import React, {Component, createRef, useState} from 'react';
import {HashRouter, Switch, Route, Link, useParams} from 'react-router-dom';
import jQuery from 'jquery';
import ReactMarkDown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './style/style.css';
import {projectsRaw} from './projects.js';

//import Tumblr from 'react-icons/lib/fa/tumblr-square';
//import Ins from 'react-icons/lib/fa/instagram';
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import rehypeRaw from "rehype-raw";

let projects = projectsRaw.filter(item => item.onHomePage)

function stringToUri(s) {
	s = s.replaceAll('\'', '');
	s = s.replaceAll(' ', '-');
	s = s.replaceAll('&', 'and');
	return s.toLowerCase();
}

export function Main() {
	return (<HashRouter hashType="noslash" onUpdate={() => window.scrollTo(0, 0)}>
		<div>
			<Switch>
				<Route exact path="/" component={()=><Home/>}/>
				<Route exact path="/about" component={()=><AboutPage/>}/>
				<Route exact path="/page/:link" component={()=><ProjectPage/>}/>
				<Route exact path="/page/:link/:section" component={()=><ProjectPage/>}/>
			</Switch>
		</div>
		<Footer/>
	</HashRouter>)
}

class Home extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (<div className="content-all container-fluid">
			<LeftCol/>
			<ProjectPreviewsContainer/>
		</div>)
	}
}

function AboutPage() {
	return <div className="content-all container-fluid">
		<div className="about-content">
			<MdContent id="text-box" relRoot="" fileName={require('./about.md')}/>
			<Back/>
		</div>
	</div>;
}

function ProjectPage() {
	let params = useParams();
	let P = null;

	for (let i = 0; i < projectsRaw.length; i++) {
		if (projectsRaw[i].link === params.link) {
			let tmp = projectsRaw[i];
			// relRoot starts at "https://miyehn.me/project/"
			P = <div className="content-all container-fluid">
				{
					tmp.toc ? <div className="toc col-xs-12 col-lg-2">
						{tmp.toc}
					</div> : ""
				}
				<div className={"project-content col-xs-12 col-lg-10" + (tmp.toc ? "" : " col-lg-push-1")}>
					<div className="project-title">{tmp.title}</div>
					<MdContent id="text-box" relRoot={"../projects/" + tmp.link + "/"} fileName={'text.md'} sectionName={params.section}/>
				</div>
				<Back/>
			</div>;
		}
	}
	return P;
}

let registerHeader = function(headerUri) {
	console.log("not implemented yet")
	return null
}

class MdContent extends Component {
	constructor(props) {
		super(props);
		jQuery.get(
			this.props.relRoot + this.props.fileName,
			data => {
				this.setState({content: data});
			});

		this.state = {
			content: 'Loading..'
		};

		this.headerRefs = new Map();
	}

	componentDidMount() {
		registerHeader = (function(headerUri) {
			let ref = createRef();
			this.headerRefs.set(headerUri, ref);
			return ref;
		}).bind(this);
	}

	componentDidUpdate() {
		//console.log(this.headerRefs);
		if (this.headerRefs.has(this.props.sectionName)) {
			//console.log("found a header to scroll to!");
			let ref = this.headerRefs.get(this.props.sectionName);
			if (ref.current) {
				ref.current.scrollIntoView({
					behavior: "smooth",
					block: "start"
				});
			}
		}
	}

	render() {
		return (<ReactMarkDown
			className="mdcontainer"
			transformImageUri={(uri)=>{
				if (uri.substring(0, 2)==='_/') {
					return this.props.relRoot + uri.substring(2);
				}
				return uri;
			}}
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeRaw]}
			components={{
				h1({children, ...otherProps}) {
					let ref = null;
					if (children && children.length > 0 && (typeof(children[0])==='string')) {
						ref = registerHeader(stringToUri(children[0]));
					}
					return <h1 ref={ref} {...otherProps}>{children}</h1>
				},
				h2({children, ...otherProps}) {
					let ref = null;
					if (children && children.length > 0 && (typeof(children[0])==='string')) {
						ref = registerHeader(stringToUri(children[0]));
					}
					return <h2 ref={ref} {...otherProps}>{children}</h2>
				},
				h3({children, ...otherProps}) {
					let ref = null;
					if (children && children.length > 0 && (typeof(children[0])==='string')) {
						ref = registerHeader(stringToUri(children[0]));
					}
					return <h3 ref={ref} {...otherProps}>{children}</h3>
				}
			}}
			children={this.state.content}/>)
	}
}


function Back() {
	return (<Link to="/">
			<div className="back clickable">Home</div>
		</Link>)
}

function LeftCol() {
	return (<div id="content-left" className="col-xs-12 col-sm-4">
			<Avatar/>
			<NameIntro/>
			<Social/>
		</div>)
}

function Avatar() {
	let avatar = "./avatar.png";
	return (<div>
			<img alt="avatar" id="avatar" src={require('' + avatar)}/>
		</div>)
}

function NameIntro() {
	return (<div>
			<div id="name">Rain Du</div>
			<MdContent id="intro" relRoot="" fileName={require("./intro.md")}/>
		</div>)
}

function Social() {
	let top5 = {position: "relative", top: 5};
	return (<div id="social">
			{/*
    <a href="https://www.instagram.com/miyehn/"><Ins className="social clickable" size={22} /></a>
      <a href="https://miyehn.tumblr.com/"><Tumblr className="social clickable" size={22} /></a>
    <a href="https://twitter.com/rain_dd"><Twitter className="social clickable" size={22} /></a>
    <br/>
		<a href="https://www.linkedin.com/in/rainduym/" className="social clickable">
			<span style={top5}><FaLinkedin size={22}/></span> Yumeng (Rain) Du
		</a><br/>
  */}
		<a href="mailto:rainduym@gmail.com" className="social clickable">
			<span style={top5}><MdEmail size={22}/></span> rainduym@gmail.com
		</a><br/>
		<a href="https://github.com/miyehn" className="social clickable">
			<span style={top5}><FaGithub size={22}/></span> miyehn
		</a>
	</div>)
}

function ProjectPreviewsContainer() {
	return <div className="snippet-container col-xs-12 col-sm-8 col-sm-push-4">
		{projects.map(item => <ProjectPreview key={item.link} project={item}/>)}
	</div>
}

function ProjectPreview(props) {
	return <Link to={"page/" + props.project.link.toString()}>
		<div className="snippet element">
			<img alt={props.project.title + " snippet"} src={'projects/' + props.project.link + '/snippet.png'}/>
			<div className="caption">
				<div className="caption-title">{props.project.title}</div>
				<div className="caption-time">{props.project.time}</div>
				<div className="caption-content">{props.project.caption}</div>
			</div>
		</div>
	</Link>
}

function Footer() {
	return <div className="footer">
		This site built from scratch using React<br/>
		Last updated 6/25/24
	</div>
}
